import { Layout } from 'components';
import { graphql } from 'gatsby';
import React from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Slice } from '../slices/Slice';
// import './legalPage.scss';
import * as styles from './legalPage.module.scss';
import Section from '../components/Section';

// TODO: make sure custom type is called LegalPage in Prismic - same for team
const LegalPage = ({ location, data: staticData }) => {
  const {
    prismicLegal: { data: pageData },
  } = staticData;

  const { title: { text: title } = {}, lead_line: { html: leadLine } = {}, body: sliceData } = pageData;
  return (
    <Layout location={location} pageData={pageData} bodyClassName="legal-page">
      <Section className={styles.legalPageBody} containerClassName={`${styles.wrapper} wrapper`}>
        {leadLine && <div dangerouslySetInnerHTML={{ __html: leadLine }} className={styles.leadLine} />}
        {title && <h1 className={styles.legalPageTitle}>{title}</h1>}
        {sliceData &&
          sliceData.map(slice => <Slice data={slice} location={location} template="legalPage" key={slice.id} />)}
      </Section>
    </Layout>
  );
};

export default withPrismicPreview(LegalPage);

export const pageQuery = graphql`
  query legalPage($uid: String!) {
    prismicLegal(uid: { eq: $uid }) {
      prismicId
      _previewable
      data {
        body {
          ...WarningStatementLegalFragment
          ...TextBlockLegalFragment
          ...AccordionLegalFragment
          ...FaqSectionLegalFragment
          ...CustomLogosLegalFragement
          ...ContactLegalQueriesFragment
        }
        lead_line {
          html
        }
        title {
          text
        }
      }
    }
  }
`;
