// extracted by mini-css-extract-plugin
export var accordion = "legalPage-module--accordion--HfD9Q";
export var accordionBlock = "legalPage-module--accordion-block--3QAfA";
export var accordionSection = "legalPage-module--accordion-section--w6r8p";
export var leadLine = "legalPage-module--lead-line--U9s9-";
export var legalPageBody = "legalPage-module--legal-page-body--1JwEP";
export var legalPageTitle = "legalPage-module--legal-page-title--tqbw-";
export var offerRoomContentBlock = "legalPage-module--offer-room-content-block--IbS2J";
export var offerRoomContentTitle = "legalPage-module--offer-room-content-title--adyqR";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var wrapper = "legalPage-module--wrapper--3ekxL";